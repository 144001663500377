import FirstEllipsePurple from "~@/assets/image/ellipses/first-ellipse-purple.png";
import FirstEllipseMobilePurple from "../assets/image/ellipse-mobile.png";
import Box from "~@/assets/image/box.png";
import Graph from "~@/assets/image/graph.png";
import Infinity from "~@/assets/image/infinity.png";
import CheckList from "~@/assets/image/checklist.png";
import Payment from "~@/assets/image/payment.png";
import Stars from "~@/assets/image/stars.png";
import TelegramWhite from "~@/assets/image/telegram-white.png";
import Line from "~@/assets/image/line.png";
import ArrowRight from "~@/assets/image/slick-arrow-right.png";
import ArrowLeft from "~@/assets/image/slick-arrow-left.png";
import Slider from "react-slick";

const ForWho = () => {
  return (
    <>
      <div
        id="forwho"
        className="flex flex-col relative mb-24 sm:min-h-screen xs:max-sm:mb-4"
      >
        <div className="absolute">
          <img
            className="block xs:max-sm:hidden"
            alt="ellipse"
            src={FirstEllipsePurple}
          />
          <img
            alt="ellipse"
            className="hidden xs:max-sm:block"
            src={FirstEllipseMobilePurple}
          />
          <span className="absolute top-[35%] ml-16 text-6xl font-bold text-green whitespace-nowrap max-lg:text-4xl xs:max-sm:ml-4 xs:max-sm:text-lg xs:max-sm:dark:text-white xs:max-sm:text-green xs:max-sm:top-[8px]">
            ДЛЯ КОГО МЫ?
          </span>
        </div>
        <div className="flex flex-col mt-52 px-12 mdxl:max-2xl:px-6 xs:max-sm:mt-14">
          <p className="text-5xl text-center text-purple xs:max-sm:text-base xs:max-sm:dark:text-white ">
            ДЛЯ ТЕХ, КТО ХОЧЕТ ПОЛУЧАТЬ:
          </p>
          <div className="hidden xs:max-sm:block xs:max-sm:mt-4">
            <Slider
              arrows={true}
              slidesToShow={1}
              slidesToScroll={1}
              nextArrow={
                <div>
                  <img alt="slick-arrow-right" src={ArrowRight} />
                </div>
              }
              prevArrow={
                <div>
                  <img alt="slick-arrow-left" src={ArrowLeft} />
                </div>
              }
            >
              <div className="flex flex-col space-y-3">
                <div className="flex flex-col justify-center">
                  <div className="flex items-center justify-center h-[55px] w-[55px] m-auto">
                    <img alt="box" src={Box} />
                  </div>
                  <p className="text-4xl text-green text-center xs:max-sm:mt-2.5 text-base dark:text-white text-base ">
                    Товар, оплачивая его рублями, гривнами, тенге и даже юсдт
                  </p>
                  <p className="text-green dark:text-white text-base mt-2.5 text-center">
                    RUB, UAH, KZT, USDT
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="flex items-center justify-center h-[55px] w-[55px] m-auto">
                    <img alt="graph" src={Graph} />
                  </div>
                  <p className="text-4xl text-green  text-center xs:max-sm:mt-2.5 text-base dark:text-white ">
                    Рыночный курс без скрытых комиссий.Платишь строго согласно
                    расчёту
                  </p>
                  <p className="text-green dark:text-white text-base mt-2.5 text-center">
                    на суммы до 2,000¥ комиссия 20¥ Свыше 2,000¥ комиссия 0%
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="flex items-center justify-center h-[55px] w-[55px] m-auto">
                    <img alt="inf" src={Infinity} />
                  </div>
                  <p className="text-4xl text-green  text-center xs:max-sm:mt-2.5 text-base dark:text-white ">
                    Возможность оплаты товара на любую сумму
                  </p>
                  <p className="text-green dark:text-white text-base mt-2.5 text-center">
                    от 10 юаней до бесконечности
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-3">
                <div className="flex flex-col justify-center">
                  <div className="flex items-center justify-center h-[55px] w-[55px] m-auto">
                    <img alt="checklist" src={CheckList} />
                  </div>
                  <p className="text-4xl text-green  text-center xs:max-sm:mt-2.5 text-base dark:text-white ">
                    Чек об оплате товара
                  </p>
                  <p className="text-center leading-tight">
                    в течениe 30 минут
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="flex items-center justify-center h-[55px] w-[55px] m-auto">
                    <img alt="payment" src={Payment} />
                  </div>
                  <p className="text-4xl text-green  text-center xs:max-sm:mt-2.5 text-base dark:text-white ">
                    Оплаты на все типы платежных систем:
                  </p>
                  <p className="text-center leading-tight">
                    вичат, алипей, банковские{" "}
                  </p>
                </div>
                <div className="flex flex-col justify-center">
                  <div className="flex items-center justify-center h-[55px] w-[55px] m-auto">
                    <img alt="stars" src={Stars} />
                  </div>
                  <p className="text-4xl text-green  text-center xs:max-sm:mt-2.5 text-base dark:text-white ">
                    Спецкурсы на суммы от 10,000 ¥
                  </p>
                </div>
              </div>
            </Slider>
          </div>
          <div className="flex flex-col mt-10 space-y-16 xs:max-sm:hidden">
            <div className="flex max-lg:flex-col">
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center h-[274px]">
                  <img alt="box" src={Box} />
                </div>
                <p className="text-4xl text-green  text-center">
                  Товар, оплачивая его рублями, гривнами, тенге и даже юсдт
                </p>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center h-[274px]">
                  <img alt="graph" src={Graph} />
                </div>
                <p className="text-4xl text-green mt-auto text-center xl3:max-hd:mt-[36px]">
                  Рыночный курс без скрытых комиссий.Платишь строго согласно
                  расчёту
                </p>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center h-[274px]">
                  <img alt="inf" src={Infinity} />
                </div>
                <p className="text-4xl text-green  text-center">
                  Возможность оплаты товара на любую сумму
                </p>
              </div>
            </div>
            <div className="flex max-lg:flex-col justify-center">
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center h-[274px]">
                  <img alt="checklist" src={CheckList} />
                </div>
                <p className="text-4xl text-green  text-center">
                  Чек об оплате товара в течении 30 минут
                </p>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center h-[274px]">
                  <img alt="payment" src={Payment} />
                </div>
                <p className="text-4xl text-green  text-center">
                  Оплаты на все типы платежных систем
                </p>
              </div>
              <div className="flex flex-col justify-center">
                <div className="flex items-center justify-center h-[274px]">
                  <img alt="stars" src={Stars} />
                </div>
                <p className="text-4xl text-green  text-center">
                  Спецкурсы на суммы от 10,000 ¥
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="xs:max-sm:rounded bg-green py-10 px-11 text-white rounded-lg text-5xl mt-24 w-fit flex space-x-5 self-center xs:max-sm:text-base xs:max-sm:py-2.5 xs:max-sm:px-4 xs:max-sm:space-x-1.5 xs:max-sm:mt-4">
          <a
            className="self-center"
            href="https://t.me/GmExchange_bot?start=r_6145797218"
            target="_blank"
            rel="noreferrer"
          >
            Добавить бота
          </a>
          <div className="self-center xs:max-sm:w-[14px]">
            <img alt="telegram-white" src={TelegramWhite} />
          </div>
        </button>
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default ForWho;
