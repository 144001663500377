import TelegramIcon from "../assets/image/telegram.png";
import BrandLogos from "../assets/image/brands-logos.png";
import BrandLogosMobile from "../assets/image/logo-brands-mobile.png";
import BrandLogosMobileLight from "../assets/image/logo-brands-mobile-dark.png";
import BrandLogosLightTheme from "../assets/image/brands-logos-light-theme.png";
import Line from "../assets/image/line.png";

const Hero = ({ isDark }: { isDark: boolean }) => {
  return (
    <>
      <div
        id="start"
        className="md:grid md:grid-cols-hero px-16 pt-36 mb-24 xs:max-sm:px-4 sm:min-h-screen xs:max-sm:pt-16 xs:max-sm:mb-4"
      >
        <div className="flex flex-col">
          <h1 className="text-title font-bold xs:max-sm:text-base xs:max-sm:text-center">
            ОПЛАЧИВАЙ ТОВАРЫ ИЗ КИТАЯ
          </h1>
          <button className="bg-purple py-10 px-11 text-white rounded-lg text-5xl mt-24 w-hero-btn xs:max-sm:text-3xl xs:max-sm:py-5 xs:max-sm:px-6 xs:max-sm:hidden">
            <a
              href="https://t.me/GmExchange_bot?start=r_6145797218"
              target="_blank"
              rel="noreferrer"
            >
              Оплатить товар
            </a>
          </button>
          <div className="hidden xs:max-sm:block xs:max-sm:mt-5">
            <img
              alt="brands-logos-mobile"
              src={isDark ? BrandLogosMobile : BrandLogosMobileLight}
            />
          </div>
          <button className="xs:max-sm:rounded hidden xs:max-sm:block bg-green w-fit self-center py-2.5 px-4 text-white rounded-lg text-base mt-6">
            <a
              href="https://t.me/GmExchange_bot?start=r_6145797218"
              target="_blank"
              rel="noreferrer"
            >
              Оплатить товар
            </a>
          </button>
          <div className="flex flex-col mt-20 xs:max-sm:mt-4">
            <div className="flex space-x-5 xs:max-sm:relative xs:max-sm:justify-center">
              <span
                className="text-4xl text-green font-semibold xs:max-sm:text-base xs:max-sm:dark:text-white 
               text-center relative"
              >
                G-MONEY EXCHANGE BOT
                <div className="xs:max-sm:w-[12px] xs:max-sm:h-[12px] xs:max-sm:m-auto xs:max-sm:absolute right-[-24px] bottom-[6px] xs:max-sm:block hidden">
                  <img alt="telegram" src={TelegramIcon} />
                </div>
              </span>
              <div className="xs:max-sm:w-[12px] xs:max-sm:h-[12px] xs:max-sm:m-auto xs:max-sm:absolute right-[-4px] bottom-[6px] xs:max-sm:hidden block">
                <img alt="telegram" src={TelegramIcon} />
              </div>
            </div>
            <span className="text-4xl mt-7 xs:max-sm:text-center xs:max-sm:text-base xs:max-sm:mt-1 xs:max-sm:px-16">
              самый быстрый и удобный способ отправить деньги в КИТАЙ через
              Telegram
            </span>
          </div>
        </div>
        <img
          alt="logos"
          className="max-lg:hidden"
          src={isDark ? BrandLogos : BrandLogosLightTheme}
        />
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default Hero;
