import FirstEllipsePurple from "~@/assets/image/ellipses/first-ellipse-purple.png";
import SecondEllipsePurple from "~@/assets/image/ellipses/second-ellipse-purple.png";
import Rocket from "~@/assets/image/rocket.png";
import Cards from "~@/assets/image/cards.png";
import Glass from "~@/assets/image/magnifying-glass.png";
import Clock from "~@/assets/image/clock.png";
import People from "~@/assets/image/ppl.png";
import Wallet from "~@/assets/image/wallet.png";
import HowItWorks from "~@/assets/image/how-it-works.png";
import Line from "~@/assets/image/line.png";
import FirstEllipseMobilePurple from "~@/assets/image/ellipse-mobile.png";
import SecondEllipseMobilePurple from "~@/assets/image/second-ellipses-mobile.png";
import ArrowDown from "~@/assets/image/down.png";
import ArrowUp from "~@/assets/image/up.png";
import { useState } from "react";

const Features = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        id="diff"
        className="flex flex-col relative mb-24 sm:min-h-screen xs:max-sm:mb-4"
      >
        <div className="absolute">
          <div className="absolute top-[25%] ml-16 text-6xl font-bold text-green flex flex-col max-lg:text-4xl xs:max-sm:ml-4 xs:max-sm:text-xl xs:max-sm:dark:text-white xs:max-sm:text-green xs:max-sm:top-[4px]">
            <span style={{ lineHeight: 1 }}>КАКИЕ НАШИ</span>
            <span style={{ lineHeight: 1 }}>ОСОБЕННОСТИ?</span>
          </div>
          <img
            className="block xs:max-sm:hidden"
            alt="ellipse"
            src={FirstEllipsePurple}
          />
          <img
            alt="ellipse"
            className="hidden xs:max-sm:block"
            src={FirstEllipseMobilePurple}
          />
        </div>
        <div className="flex space-x-28 mt-60 px-16 max-lg:flex-col max-lg:space-x-0 max-lg:space-y-16 xs:max-sm:px-4 xs:max-sm:mt-16 xs:max-sm:space-y-5">
          <div className="flex space-x-10 xs:max-sm:hidden">
            <div className="flex items-center">
              <img alt="rocket" src={Rocket} />
            </div>
            <div className="flex flex-col">
              <p className="text-4xl text-purple xs:max-sm:text-2xl">
                Максимально автоматизированный
              </p>
              <p className="text-4xl text-purple xs:max-sm:text-2xl">
                быстрый обмен
              </p>
              <p className="text-3xl text-green xs:max-sm:text-xl mt-auto">
                удобный бот для работы со всеми валютами принимающий заявки 24/7
              </p>
            </div>
          </div>
          <div className="hidden xs:max-sm:flex xs:max-sm:flex-col mt-[0!important]">
            <div className="flex items-center">
              <img
                className="xs:max-sm:w-[28px] xs:max-sm:mr-2.5"
                alt="rocket"
                src={Rocket}
              />
              <p className="text-4xl text-purple xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:hidden">
                Максимально автоматизированный быстрый обмен
              </p>
              <div className="hidden xs:max-sm:flex flex-col">
                <p className="text-4xl text-purple xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:block hidden">
                  Максимально автоматизированный
                </p>
                <p className="text-4xl text-purple xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:block hidden">
                  быстрый обмен
                </p>
              </div>
            </div>
            <p className="text-3xl text-green xs:max-sm:dark:text-white  mt-auto xs:max-sm:text-sm xs:max-sm:mt-2 mt-10">
              удобный бот для работы со всеми валютами принимающий заявки{" "}
              <span className="text-[#ff0000]">24/7</span>
            </p>
          </div>
          <div className="flex space-x-10 xs:max-sm:hidden">
            <div className="flex items-center">
              <img alt="cards" src={Cards} />
            </div>
            <div className="flex flex-col">
              <div>
                <p className="text-4xl text-purple xs:max-sm:text-2xl">
                  Только внутренние
                </p>
                <p className="text-4xl text-purple xs:max-sm:text-2xl">
                  платежи
                </p>
              </div>
              <p className="text-3xl text-green xs:max-sm:text-xl mt-10">
                оплата всегда внутри Китая, что удобно всем поставщикам, эконом
              </p>
            </div>
          </div>
          <div className="hidden xs:max-sm:flex xs:max-sm:flex-col">
            <div className="flex items-center">
              <img
                className="xs:max-sm:w-[36px] xs:max-sm:mr-2.5"
                alt="cards"
                src={Cards}
              />
              <div className="xs:max-sm:flex flex-col block">
                <p className="text-4xl text-purple xs:max-sm:text-base xs:max-sm:dark:text-white ">
                  Только внутренние
                </p>
                <p className="text-4xl text-purple xs:max-sm:text-base xs:max-sm:dark:text-white ">
                  платежи
                </p>
              </div>
            </div>
            <p className="text-3xl text-green xs:max-sm:dark:text-white  mt-auto xs:max-sm:text-sm xs:max-sm:mt-2">
              оплата всегда внутри Китая, что удобно всем поставщикам, эконом
            </p>
          </div>
        </div>
        <div className="mt-28 flex justify-center mb-24 xs:max-sm:mt-3.5 xs:max-sm:mb-4">
          <div className="w-fit relative xs:max-sm:flex xs:max-sm:items-center">
            <div className="hidden xs:max-sm:block mr-2.5">
              <img
                onClick={() => setIsOpen(!isOpen)}
                src={isOpen ? ArrowUp : ArrowDown}
                alt="arrow-down"
              />
            </div>
            <p className="w-fit text-center relative text-6xl font-bold text-green z-20 xs:max-sm:text-lg xs:max-sm:dark:text-white">
              НАШИ ФИЧИ
            </p>
            <div className="absolute bottom-[-85%] left-[35%] z-[0] w-full xs:max-sm:right-0 xs:max-sm:-0 xs:max-sm:bottom-0 xs:max-sm:w-auto xs:max-sm:right-[-16px] xs:max-sm:top-0 xs:max-sm:left-auto">
              <img
                className="block xs:max-sm:hidden"
                alt="ellipse-purple"
                src={SecondEllipsePurple}
              />
              <img
                className="hidden xs:max-sm:block"
                alt="ellipse-purple"
                src={SecondEllipseMobilePurple}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-36 px-28 text-4xl max-lg:flex-col max-lg:space-x-0 max-lg:space-y-16 xs:max-sm:px-4 xs:max-sm:space-y-4">
          <div className="block xs:max-sm:flex flex-col">
            <div className="flex flex-col h-[520px] xs:max-sm:h-auto xs:max-sm:flex-row-reverse xs:max-sm:items-center xs:max-sm:justify-end">
              <p className="text-purple xs:max-sm:hidden">
                Мгновенный ответ и выставление расчета
              </p>
              <div className="hidden xs:max-sm:block xs:max-sm:text-green xs:max-sm:text-base">
                <p>Мгновенный ответ и выставление</p>
                <p>расчета</p>
              </div>
              <div className="flex flex-col mt-auto xs:max-sm:mt-0">
                <div className="flex self-center  h-[90px] w-[90px] mb-24 xs:max-sm:w-[30px] xs:max-sm:h-[30px] xs:max-sm:m-auto xs:max-sm:mr-2.5">
                  <img alt="rocket" src={Rocket} />
                </div>
                <p className="text-3xl text-green xs:max-sm:hidden">
                  наш бот не будет тебя томить и тут же отреагирует на твой
                  запрос
                </p>
              </div>
            </div>
            <p
              className={
                isOpen
                  ? "text-sm dark:text-white text-black mt-1"
                  : "text-3xl text-green hidden"
              }
            >
              наш бот не будет тебя томить и тут же отреагирует на твой запрос
            </p>
          </div>
          <div className="block xs:max-sm:flex flex-col">
            <div className="flex flex-col h-[520px] xs:max-sm:h-auto xs:max-sm:flex-row-reverse xs:max-sm:items-center xs:max-sm:justify-end">
              <p className="text-purple xs:max-sm:hidden">
                Круглосуточный доступ к своей истории оплат
              </p>
              <div className="hidden xs:max-sm:block xs:max-sm:text-green xs:max-sm:text-base">
                <p>Круглосуточный доступ к своей</p>
                <p>истории оплат</p>
              </div>
              <div className="flex flex-col mt-auto xs:max-sm:mt-0">
                <div className="flex self-center  h-[90px] w-[90px] mb-24 xs:max-sm:w-[30px] xs:max-sm:h-[30px] xs:max-sm:m-auto xs:max-sm:mr-2.5">
                  <img alt="clock" src={Clock} />
                </div>
                <p className="text-3xl text-green xs:max-sm:hidden">
                  всегда можешь посмотреть детали того или иного обмена
                </p>
              </div>
            </div>
            <p
              className={
                isOpen
                  ? "text-sm dark:text-white text-black mt-1"
                  : "text-3xl text-green hidden"
              }
            >
              всегда можешь посмотреть детали того или иного обмена
            </p>
          </div>
          <div className="block xs:max-sm:flex flex-col">
            <div className="flex flex-col h-[520px] xs:max-sm:h-auto xs:max-sm:flex-row-reverse xs:max-sm:items-center xs:max-sm:justify-end">
              <p className="text-purple xs:max-sm:hidden">
                Возможность отслеживания статуса того или иного платежа
              </p>
              <div className="hidden xs:max-sm:block xs:max-sm:text-green xs:max-sm:text-base">
                <p>Возможность отслеживания статуса</p>
                <p>платежа</p>
              </div>
              <div className="flex flex-col mt-auto xs:max-sm:mt-0">
                <div className="flex self-center  h-[90px] w-[90px] mb-24 xs:max-sm:w-[30px] xs:max-sm:h-[30px] xs:max-sm:m-auto xs:max-sm:mr-2.5">
                  <img alt="glass" src={Glass} />
                </div>
                <p className="text-3xl text-green xs:max-sm:hidden">
                  бот заботится чтобы ты не нервничал и был в курсе каждого
                  этапа сделки
                </p>
              </div>
            </div>
            <p
              className={
                isOpen
                  ? "text-sm dark:text-white text-black mt-1"
                  : "text-3xl text-green hidden"
              }
            >
              бот заботится чтобы ты не нервничал и был в курсе каждого этапа
              сделки
            </p>
          </div>
        </div>
        <div className="flex space-x-36 justify-center px-80 mt-24 text-4xl max-lg:flex-col max-lg:space-x-0 max-lg:space-y-16 max-lg:px-28 xs:max-sm:px-4 xs:max-sm:mt-4 xs:max-sm:space-y-4">
          <div className="block xs:max-sm:flex flex-col">
            <div className="flex flex-col h-[520px] xs:max-sm:h-auto xs:max-sm:flex-row-reverse xs:max-sm:items-center xs:max-sm:justify-end">
              <p className="text-purple xs:max-sm:hidden">
                Встроенный кошелек во всех доступных валютах
              </p>
              <div className="hidden xs:max-sm:block xs:max-sm:text-green xs:max-sm:text-base">
                <p>Встроенный кошелек во всех</p>
                <p>доступных валютах</p>
              </div>
              <div className="flex flex-col mt-auto xs:max-sm:mt-0">
                <div className="flex self-center  h-[90px] w-[90px] mb-24 xs:max-sm:w-[30px] xs:max-sm:h-[30px] xs:max-sm:m-auto xs:max-sm:mr-2.5">
                  <img alt="wallet" src={Wallet} />
                </div>
                <p className="text-3xl text-green xs:max-sm:hidden">
                  можешь проводить оплаты в один клик в удобное для тебя время
                </p>
              </div>
            </div>
            <p
              className={
                isOpen
                  ? "text-sm dark:text-white text-black mt-1"
                  : "text-3xl text-green hidden"
              }
            >
              можешь проводить оплаты в один клик в удобное для тебя время
            </p>
          </div>
          <div className="block xs:max-sm:flex flex-col">
            <div className="flex flex-col h-[520px] xs:max-sm:h-auto xs:max-sm:flex-row-reverse xs:max-sm:items-center xs:max-sm:justify-end">
              <p className="text-purple xs:max-sm:hidden">
                Партнерская программа для заработка
              </p>
              <div className="hidden xs:max-sm:block xs:max-sm:text-green xs:max-sm:text-base">
                <p>Партнерска программа для</p>
                <p>заработка</p>
              </div>
              <div className="flex flex-col mt-auto xs:max-sm:mt-0">
                <div className="flex self-center  h-[90px] mb-24 xs:max-sm:w-[32px] xs:max-sm:h-[22px] xs:max-sm:m-auto xs:max-sm:mr-2.5">
                  <img alt="people" src={People} />
                </div>
                <p className="text-3xl text-green xs:max-sm:hidden">
                  делись персональной ссылкой на сервис и получай выплаты с
                  каждой сделки
                </p>
              </div>
            </div>
            <p
              className={
                isOpen
                  ? "text-sm dark:text-white text-black mt-1"
                  : "text-3xl text-green hidden"
              }
            >
              делись персональной ссылкой на сервис и получай выплаты с каждой
              сделки
            </p>
          </div>
        </div>
        <div className="text-4xl flex text-green space-x-5 mt-24 self-center xs:max-sm:hidden">
          <span>Как это работает?</span>
          <div>
            <img alt="how" src={HowItWorks} />
          </div>
        </div>
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default Features;
