import FirstEllipsePurple from "~@/assets/image/ellipses/first-ellipse-purple.png";
import Feedback from "~@/assets/image/feedback.png";
import FeedbackMobile from "~@/assets/image/reviews-mobile.png";
import TelegramWhite from "~@/assets/image/telegram-white.png";
import Line from "~@/assets/image/line.png";
import FirstEllipseMobilePurple from "~@/assets/image/ellipse-mobile.png";
const Interaction = () => {
  return (
    <>
      <div
        id="reviews"
        className="flex flex-col relative mb-24 sm:min-h-screen xs:max-sm:mb-3"
      >
        <div className="absolute w-full">
          <div className="absolute top-[25%] ml-16 text-6xl font-bold text-green flex flex-col whitespace-nowrap max-2xl:text-4xl max-lg:text-2xl xs:max-sm:ml-2 xs:max-sm:whitespace-normal xs:max-sm:text-lg xs:max-sm:top-[8px] xs:max-sm:dark:text-white xs:max-sm:text-green xs:max-sm:font-semibold">
            <span style={{ lineHeight: 1 }}>НА ВОПРОС</span>
            <span
              style={{ lineHeight: 1 }}
              className="text-orange xs:max-sm:text-green"
            >
              "КАК ВАМ ВЗАИМОДЕЙСТВИЕ С БОТОМ"
            </span>
            <span
              style={{ lineHeight: 1 }}
              className="xs:max-sm:whitespace-nowrap"
            >
              КЛИЕНТЫ ОТВЕЧАЮТ:
            </span>
          </div>
          <img
            className="block xs:max-sm:hidden"
            alt="ellipse"
            src={FirstEllipsePurple}
          />
          <img
            alt="ellipse"
            className="hidden xs:max-sm:block"
            src={FirstEllipseMobilePurple}
          />
        </div>
        <div className="mt-80 flex flex-col xs:max-sm:mt-20">
          <img
            className="block xs:max-sm:hidden"
            alt="feedback"
            src={Feedback}
          />
          <img
            className="hidden xs:max-sm:block"
            alt="feedback"
            src={FeedbackMobile}
          />
          <button className="xs:max-sm:rounded bg-green py-10 px-11 text-white rounded-lg text-5xl mt-24 w-fit flex space-x-5 self-center xs:max-sm:text-base xs:max-sm:py-2.5 xs:max-sm:px-4 xs:max-sm:space-x-1.5 xs:max-sm:mt-4">
            <a
              href="https://t.me/GmExchange_bot?start=r_6145797218"
              target="_blank"
              className="self-center"
              rel="noreferrer"
            >
              Попробовать бота
            </a>
            <div className="self-center xs:max-sm:w-[14px]">
              <img alt="telegram-white" src={TelegramWhite} />
            </div>
          </button>
        </div>
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default Interaction;
