import FirstEllipsePurple from "~@/assets/image/ellipses/first-ellipse-purple.png";

import Line from "~@/assets/image/line.png";
import Tether from "~@/assets/image/brands/tether.png";
import Kaspi from "~@/assets/image/brands/kaspi.png";
import Private from "~@/assets/image/brands/private.png";
import Mono from "~@/assets/image/brands/mono.png";
import Tinkoff from "~@/assets/image/brands/tinkoff.png";
import Sberbank from "~@/assets/image/brands/sberbank.png";
import Alpha from "~@/assets/image/brands/alpha-bank.png";
import Vtb from "~@/assets/image/brands/VTB.png";

import Alipay from "~@/assets/image/brands/alipay.png";
import WeChat from "~@/assets/image/brands/WeChat.png";
import Abc from "~@/assets/image/brands/ABC.png";
import ChinaCon from "~@/assets/image/brands/CCB.png";
import Icbc from "~@/assets/image/brands/icbc.png";
import Boc from "~@/assets/image/brands/BofC.png";
import Cmb from "~@/assets/image/brands/china-merchant.png";
import PostalBank from "~@/assets/image/brands/postal-bank.png";
import FirstEllipseMobilePurple from "~@/assets/image/ellipse-mobile.png";
import LineMobile from "~@/assets/image/line-mobile.png";
const Services = () => {
  return (
    <>
      <div
        id="services"
        className="flex flex-col relative mb-24 sm:min-h-screen xs:max-sm:mb-8"
      >
        <div className="absolute">
          <div className="absolute top-[25%] ml-16 text-6xl font-bold text-green flex flex-col whitespace-nowrap max-lg:text-4xl xs:max-sm:text-xl xs:max-sm:ml-4 xs:max-sm:top-[6px] xs:max-sm:dark:text-white xs:max-sm:text-green">
            <span style={{ lineHeight: 1 }}>СЕРВИСЫ, С КОТОРЫМИ</span>
            <span style={{ lineHeight: 1 }}>МЫ РАБОТАЕМ</span>
          </div>
          <img
            className="block xs:max-sm:hidden"
            alt="ellipse"
            src={FirstEllipsePurple}
          />
          <img
            alt="ellipse"
            className="hidden xs:max-sm:block"
            src={FirstEllipseMobilePurple}
          />
        </div>
        <div className="mt-80 flex justify-between text-green max-2xl:flex-wrap xs:max-sm:mt-16 md:max-2xl:justify-between xs:max-sm:space-x-0 px-4 xs:max-sm:justify-center 2xl:justify-center 2xl:space-x-48">
          <div className="flex flex-col xs:max-sm:w-full xs:max-sm:space-y-6 md:max-2xl:w-1/2">
            <div className="grid grid-cols-brands space-x-4 gap-0 xs:max-sm:flex xs:max-sm:flex-row xs:max-sm:space-x-0 xs:max-sm:gap-0 xs:max-sm:justify-between h-[250px] xs:max-sm:h-auto">
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="tether" src={Tether} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Tether(USDT)
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="kaspi" src={Kaspi} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Каспи банк
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="private" src={Private} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  ПриватБанк
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="mono" src={Mono} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  МоноБанк
                </p>
              </div>
            </div>
            <div className="grid grid-cols-brands space-x-4 gap-0 xs:max-sm:flex xs:max-sm:flex-row xs:max-sm:space-x-0 xs:max-sm:gap-0 xs:max-sm:justify-between h-[250px] xs:max-sm:h-auto">
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="tinkoff" src={Tinkoff} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Тинькофф
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="sberbank" src={Sberbank} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Сбербанк
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="alpha-bank" src={Alpha} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Альфа Банк
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="VTB" src={Vtb} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  ВТБ
                </p>
              </div>
            </div>
          </div>
          <div className="hidden xs:max-sm:block my-6">
            <img alt="line-mobile" src={LineMobile} />
          </div>
          <div className="flex flex-col xs:max-sm:w-full xs:max-sm:space-y-6 md:max-2xl:w-1/2">
            <div className="grid grid-cols-brands space-x-4 gap-0 xs:max-sm:flex xs:max-sm:flex-row xs:max-sm:space-x-0 xs:max-sm:gap-0 xs:max-sm:justify-between h-[250px] xs:max-sm:h-auto">
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="alipay" src={Alipay} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Alipay
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="wechat" src={WeChat} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  WeChat
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="ABC" src={Abc} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  ABC
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="chinacon" src={ChinaCon} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  China Construction
                </p>
              </div>
            </div>
            <div className="grid grid-cols-brands space-x-4 gap-0 xs:max-sm:flex xs:max-sm:flex-row xs:max-sm:space-x-0 xs:max-sm:gap-0 xs:max-sm:justify-between h-[250px] xs:max-sm:h-auto">
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="ICBC" src={Icbc} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  ICBC
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="BOC" src={Boc} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Bank of China
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="CMB" src={Cmb} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  China Merchants Bank
                </p>
              </div>
              <div className="flex flex-col items-center xs:max-sm:w-[20%]">
                <div className="mb-8 w-[115px] h-[115px] xs:max-sm:w-[45px] xs:max-sm:h-[45px] xs:max-sm:mb-3">
                  <img alt="postal-bank" src={PostalBank} />
                </div>
                <p className="text-3xl text-center xs:max-sm:text-base xs:max-sm:dark:text-white  xs:max-sm:whitespace-normal">
                  Postal Bank
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default Services;
