import FirstEllipsePurple from "../assets/image/ellipses/first-ellipse-purple.png";
import FirstEllipseMobilePurple from "../assets/image/ellipse-mobile.png";
import EllipseGreen from "../assets/image/ellipse-green.png";
import EllipseGreenMobile from "../assets/image/ellipse-green-mobile.png";
import GreenDot from "../assets/image/green-dot.png";
import China from "../assets/image/china.png";
import ChinaMobile from "../assets/image/china-mobile.png";
import Line from "~@/assets/image/line.png";

const About = () => {
  return (
    <>
      <div
        id="about"
        className="flex flex-col relative mb-24 sm:min-h-screen xs:max-sm:mt-1 xs:max-sm:mb-4"
      >
        <div className="absolute">
          <img
            className="block xs:max-sm:hidden"
            alt="ellipse"
            src={FirstEllipsePurple}
          />
          <img
            alt="ellipse"
            className="hidden xs:max-sm:block"
            src={FirstEllipseMobilePurple}
          />
          <span className="absolute top-[35%] ml-16 text-6xl font-bold text-green whitespace-nowrap max-lg:text-4xl xs:max-sm:ml-4 xs:max-sm:text-lg xs:max-sm:dark:text-white xs:max-sm:text-green xs:max-sm:top-[8px]">
            КТО МЫ?
          </span>
        </div>
        <div className="px-20 mt-52 xs:max-sm:px-4 xs:max-sm:mt-12">
          <p className="text-5xl xs:max-sm:hidden">
            Сервис, который поможет тебе оплатить товары
          </p>
          <p className="hidden text-5xl xs:max-sm:text-base xs:max-sm:block">
            Сервис, который поможет тебе оплатить
          </p>
          <p className="hidden text-5xl xs:max-sm:text-base xs:max-sm:block">
            товары
          </p>
          <div className="flex mt-24 space-x-48 max-[1820px]:flex-col max-[1820px]:space-y-16 max-sm:space-x-0 xs:max-sm:mt-3 xs:max-sm:space-y-4">
            <div className="flex space-x-5 xs:max-sm:flex-col xs:max-sm:items-center">
              <div className="flex flex-col space-y-1 text-5xl xs:max-sm:text-2xl whitespace-nowrap justify-end xs:max-sm:flex-row xs:max-sm:justify-center xs:max-sm:space-y-0 xs:max-sm:space-x-12">
                <div className="flex space-x-5 xs:max-sm:space-x-2.5">
                  <div className="min-w-fit flex items-center">
                    <img
                      alt="green-dot"
                      className="xs:max-sm:h-[5px] xs:max-sm:w-[5px]"
                      src={GreenDot}
                    />
                  </div>
                  <p className="xs:max-sm:text-base">на поставщиков</p>
                </div>
                <div className="flex space-x-5 xs:max-sm:space-x-2.5">
                  <div className="min-w-fit flex items-center">
                    <img
                      className="xs:max-sm:h-[5px] xs:max-sm:w-[5px]"
                      alt="green-dot"
                      src={GreenDot}
                    />
                  </div>
                  <p className="xs:max-sm:text-base">на компании</p>
                </div>
                <div className="flex space-x-5 xs:max-sm:space-x-2.5 xs:max-sm:hidden">
                  <div className="min-w-fit flex items-center">
                    <img
                      className="xs:max-sm:h-[5px] xs:max-sm:w-[5px]"
                      alt="green-dot"
                      src={GreenDot}
                    />
                  </div>
                  <p className="xs:max-sm:text-base">на любых площадках</p>
                </div>
              </div>
              <div className="hidden space-x-5 xs:max-sm:space-x-2.5 xs:max-sm:flex xs:max-sm:mt-3">
                <div className="min-w-fit flex items-center">
                  <img
                    className="xs:max-sm:h-[5px] xs:max-sm:w-[5px]"
                    alt="green-dot"
                    src={GreenDot}
                  />
                </div>
                <p className="xs:max-sm:text-base">на любых площадках</p>
              </div>
            </div>
            <div className="flex items-center max-lg:hidden xs:max-sm:flex xs:max-sm:justify-center">
              <span
                style={{ lineHeight: "1 !important" }}
                className="text-10xl text-red font-bold leading-[0.75] max-[1820px]:text-[200px] xs:max-sm:text-[40px] xs:max-sm:mr-4"
              >
                КИТАЯ
              </span>
              <div className="w-full xs:max-sm:w-auto">
                <img
                  className="block xs:max-sm:hidden"
                  alt="china"
                  src={China}
                />
                <img
                  className="hidden xs:max-sm:block"
                  alt="china"
                  src={ChinaMobile}
                />
              </div>
            </div>
          </div>
          <p className="text-5xl mt-24 xs:max-sm:text-base xs:max-sm:mt-10 xs:max-sm:text-center xs:max-sm:hidden">
            Платежи принимаем и отправляем как с компании, так и от частного
            лица
          </p>
          <p className="xs:max-sm:text-base xs:max-sm:mt-7 hidden xs:max-sm:block">
            Платежи принимаем и отправляем как c
          </p>
          <p className="xs:max-sm:text-base hidden xs:max-sm:block">
            компании, так и от частного лица
          </p>
          <div className="flex mt-24 space-x-10 justify-center mt-24 max-2xl:items-center xs:max-sm:flex-row xs:max-sm:space-x-8 xs:max-sm:justify-center xs:max-sm:mt-9">
            <div className="flex flex-col dark:text-white xs:max-sm:dark:text-white">
              <div className="relative">
                <p className="text-8xl relative z-10 xs:max-sm:text-4xl">
                  <span className="text-7xl xs:max-sm:text-base xs:max-sm:mr-2">
                    с
                  </span>
                  2015
                  <span className="text-7xl align-text-top xs:max-sm:text-xl xs:max-sm:ml-1.5">
                    года
                  </span>
                </p>
                <div className="absolute right-0 bottom-0 max-2xl:left-[15%]">
                  <img
                    className="block xs:max-sm:hidden"
                    alt="ellipse-green"
                    src={EllipseGreen}
                  />
                  <img
                    className="hidden xs:max-sm:block"
                    alt="ellipse-green"
                    src={EllipseGreenMobile}
                  />
                </div>
              </div>
              <p className="text-4xl mt-20 text-center max-2xl:mt-0 xs: max-sm:text-base xs:max-sm:mt-5">
                НА РЫНКЕ
              </p>
            </div>
            <div className="flex flex-col dark:text-white xs:max-sm:dark:text-white">
              <div className="relative">
                <p className="text-8xl relative z-10 xs:max-sm:text-4xl">
                  17 000
                  <span className="text-7xl align-text-top xs:max-sm:text-2xl xs:max-sm:ml-1">
                    +
                  </span>
                </p>
                <div className="absolute right-0 bottom-0 max-2xl:left-[15%]">
                  <img
                    className="block xs:max-sm:hidden"
                    alt="ellipse-green"
                    src={EllipseGreen}
                  />
                  <img
                    className="hidden xs:max-sm:block"
                    alt="ellipse-green"
                    src={EllipseGreenMobile}
                  />
                </div>
              </div>
              <p className="text-4xl mt-20 text-center whitespace-nowrap max-2xl:mt-0 xs:max-sm:text-base xs:max-sm:hidden">
                ЛЮБИМЫХ КЛИЕНТОВ
              </p>
              <p className="text-4xl mt-20 text-center whitespace-nowrap max-2xl:mt-0 xs:max-sm:text-base xs:max-sm:mt-5 hidden xs:max-sm:block">
                ЛЮБИМЫХ
              </p>
              <p className="text-4xl text-center whitespace-nowrap max-2xl:mt-0 xs:max-sm:text-base hidden xs:max-sm:block">
                КЛИЕНТОВ
              </p>
            </div>
            <div className="flex flex-col xs:max-sm:hidden dark:text-white">
              <div className="relative">
                <p className="text-8xl relative z-10 whitespace-nowrap xs:max-sm:text-4xl">
                  250 000
                  <span className="text-7xl align-text-top xs:max-sm:text-2xl xs:max-sm:ml-1">
                    +
                  </span>
                </p>
                <div className="absolute right-0 bottom-0 max-2xl:left-[15%]">
                  <img
                    className="block xs:max-sm:hidden"
                    alt="ellipse-green"
                    src={EllipseGreen}
                  />
                  <img
                    className="hidden xs:max-sm:block"
                    alt="ellipse-green"
                    src={EllipseGreenMobile}
                  />
                </div>
              </div>
              <p className="text-4xl mt-20 text-center whitespace-nowrap max-2xl:mt-0 xs:max-sm:text-base">
                СОВЕРШЕННЫХ СДЕЛОК
              </p>
            </div>
          </div>
          <div className="hidden flex-col xs:max-sm:flex xs:max-sm:w-fit xs:max-sm:m-auto xs:max-sm:mt-4 xs:max-sm:dark:text-white">
            <div className="relative">
              <p className="text-8xl relative z-10 whitespace-nowrap xs:max-sm:text-4xl">
                250 000
                <span className="text-7xl align-text-top xs:max-sm:text-2xl xs:max-sm:ml-1">
                  +
                </span>
              </p>
              <div className="absolute right-0 bottom-0 max-2xl:left-[15%]">
                <img
                  className="block xs:max-sm:hidden"
                  alt="ellipse-green"
                  src={EllipseGreen}
                />
                <img
                  className="hidden xs:max-sm:block"
                  alt="ellipse-green"
                  src={EllipseGreenMobile}
                />
              </div>
            </div>
            <p className="text-4xl mt-20 text-center whitespace-nowrap max-2xl:mt-0 xs:max-sm:text-base xs:max-sm:mt-5">
              СОВЕРШЕННЫХ
            </p>
            <p className="text-4xl mt-20 text-center whitespace-nowrap max-2xl:mt-0 xs:max-sm:text-base">
              СДЕЛОК
            </p>
          </div>
        </div>
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default About;
