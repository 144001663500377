import { useState } from "react";

import ArrowUp from "~@/assets/image/arrow-up.png";
import ArrowDown from "~@/assets/image/arrow-down.png";

interface ExpandProps {
  heading: string;
  children: React.ReactElement | React.ReactElement[];
}

const Expand: React.FC<ExpandProps> = ({ heading, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className="flex justify-between items-center p-4 border-b-8 border-purple cursor-pointer xs:max-sm:border-b-2 xs:max-sm:p-2.5 "
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <h2 className="dark:text-white text-green text-3xl xs:max-sm:text-base">
          {heading}
        </h2>
        {isOpen ? (
          <img src={ArrowUp} width="20" alt="arrow" />
        ) : (
          <img src={ArrowDown} width="20" alt="arrow" />
        )}
      </div>
      {isOpen && (
        <div className="p-4 text-2xl dark:text-white text-green space-y-8 xs:max-sm:text-sm xs:max-sm:tracking-[0.28px]">
          {children}
        </div>
      )}
    </>
  );
};

export default Expand;
