import Expand from "./Expand";

import FirstEllipsePurple from "../assets/image/ellipses/first-ellipse-purple.png";
import FirstEllipseMobilePurple from "~@/assets/image/ellipse-mobile.png";

const FAQ = () => (
  <div id="faq" className="flex flex-col relative">
    <div className="absolute">
      <img
        className="block xs:max-sm:hidden"
        alt="ellipse"
        src={FirstEllipsePurple}
      />
      <img
        alt="ellipse"
        className="hidden xs:max-sm:block"
        src={FirstEllipseMobilePurple}
      />
      <span className="absolute top-[35%] ml-16 text-6xl font-bold text-green whitespace-nowrap max-lg:text-4xl xs:max-sm:dark:text-white xs:max-sm:text-green xs:max-sm:text-base xs:max-sm:top-[8px] xs:max-sm:ml-4">
        FAQ
      </span>
    </div>
    <div className="px-4 py-52 md:px-52 xs:max-sm:py-16">
      <Expand heading="О компании">
        <p>🗃 О компании G-Money Exchange</p>
        <p>
          ⭐️G-Money Exchange - компания зарекомендовавшая себя как
          профессиональный игрок на рынке переводов с 2015 года:
          <br />
          ⁃ 👩‍❤️‍👨более 17’000 постоянных клиентов
          <br />
          ⁃ 🚀свыше 100’000 обменов произведено за 8 лет работы
          <br />
          ⁃ ❤️10’000+ отзывов от любимых клиентов
          <br />
        </p>
        <p>
          Пользуясь нашими услугами, клиенты G-Money с лёгкостью могут:
          <br />
          -🏭оплачивать товары поставщикам и на фабрики через 🔹Alipay и карту
          Китая в любое удобное время, ведь мы работаем 365 дней в году 🌏
        </p>
        <p>
          📬Наши официальные контакты:
          <ol>
            <li>@GmExchange_bot - Телеграм бот 🤖</li>
            <li>@GmExchange - Администратор бота</li>
            <li>GmExchange - WeChat ID</li>
            <li>https://gm.exchange - Website</li>
          </ol>
        </p>
        <p>‼️Всегда проверяйте username ID и адресную строку сайта‼️</p>
      </Expand>
      <Expand heading="✅ Какие гарантии?">
        <p>
          ✅Гарантией нашей качественной работы служит многолетняя репутация и
          тысячи довольных клиентов. Что бы убедится в подлинности нашего
          аккаунта и не попасть на мошенников, рекомендуем проверять всегда
          @username бота (GmExchange_bot) и добавить наш рабочий аккаунт в
          WeChat, обязательно сверив WeChat ID: GmExchange
          <br />
          ❗️(WeChat ID задаются единоразово и являются уникальными именами
          аккаунтов, которые невозможно изменить) ❗️
          <br />
          💚На странице WeChat аккаунта, в моментах, можно ознакомиться с лентой
          отзывов, убедившись в длительной истории работы сервиса по датам
          публикации.
          <br />
          💕Также, ты всегда можешь разбить свою сумму на любые удобные части,
          отправляя следующую, после зачисления предыдущей.
        </p>
      </Expand>
      <Expand heading="💰Как обменять крупную сумму?">
        <p>
          💰Чтобы обменять сумму свыше лимитов указанных в боте ✍️ напиши запрос
          админу @GmExchange указав сумму и направление перевода/
        </p>
        <p>
          Например:
          <br />
          Привет👋 Нужно обменять Х 🇨🇳CNY, на 🇷🇺RUB, хочу спецкурс.
        </p>
        <p>Где «Х» предполагаемая сумма обмена.</p>
      </Expand>
      <Expand heading="🔍Поиск по номеру заявки">
        <p>
          🔍Чтобы осуществить поиск по номеру заявки достаточно кликнуть в чате
          на #хэштег заявки
          <br />
          ⬆️⬇️Используя стрелки внизу ты можешь легко перемещайся по информации
          в чате, касающейся конкретной заявки
        </p>
      </Expand>
      <Expand heading="💰Кошелек">
        <p>
          В 💰Кошельке ты можешь хранить следующие валюты: 🇨🇳CNY, 🇷🇺RUB, 🇺🇦UAH,
          🇺🇸USDT, 🇰🇿KZT.
        </p>
        <p>
          Ты можешь 💸Пополнить кошелек любым удобным для тебя способом, а потом
          совершить 🔁Обмен на любую сумму доступную на твоём балансе
        </p>
        <p>
          Чтобы не оплачивать каждую заявку отдельно, достаточно, чтоб при
          оформлении новой заявки на обмен, твой баланс был больше или равен
          сумме требуемой для оплаты заявки, тогда бот 🤖 в автоматическом
          режиме предложит тебе на этапе оформления заявки списать с баланса
          сумму для оплаты обмена
        </p>
        <p>
          ☝️Также все заработанные деньги полученные с 👫Партнерской программы
          автоматически начисляются на баланс своего 💰Кошелька и доступны к
          📤Выводу в любую минуту
        </p>
        <p>
          P.S.: в ⚙️Настройках, для удобства, можешь выбрать валюту, в которой
          будет отображаться общий баланс кошелька и сумма доступная для обмена
          по текущему курсу.
        </p>
      </Expand>
      <Expand heading="🤖 Мой график работы">
        <p>
          Создание и прием заявок - Круглосуточно 24/7/365 <br />
          Обработка заявок - <br />
          13:00-22:00 по Пекину <br />
          08:00-18:00 по МСК
          <br />
          07:00-17:00 по Киеву
          <br />
          11:00-20:00 по Астане
          <br />
          Без выходных
        </p>
      </Expand>
    </div>
  </div>
);

export default FAQ;
