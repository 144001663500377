import "~@/assets/css/style.css";
import Header from "~@/components/Header";
import Hero from "~@/components/Hero";
import About from "~@/components/About";
import ForWho from "~@/components/ForWho";
import Features from "~@/components/Features";
import Telegram from "~@/components/Telegram";
import Services from "~@/components/Services";
import Interaction from "~@/components/Interaction";
import FAQ from "~@/components/FAQ";
import { useState } from "react";

function App() {
  const [darkToggle, setDarkToggle] = useState(
    "isDark" in localStorage
      ? JSON.parse(localStorage.isDark)
      : window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  return (
    <div className={`h-full ${darkToggle ? "dark" : ""}`}>
      <div className="font-myriad bg-light-gray dark:bg-dark-gray dark:text-white text-slate-900">
        <Header
          toggleTheme={() => {
            if ("isDark" in localStorage)
              localStorage["isDark"] = !JSON.parse(localStorage["isDark"]);
            else
              localStorage["isDark"] = !window.matchMedia(
                "(prefers-color-scheme: dark)"
              ).matches;
            setDarkToggle(!JSON.parse(darkToggle));
          }}
          isDarkTheme={darkToggle}
        />
        <Hero isDark={darkToggle} />
        <About />
        <ForWho />
        <Features />
        <Telegram />
        <Services />
        <Interaction />
        <FAQ />
      </div>
    </div>
  );
}

export default App;
