import FirstEllipsePurple from "~@/assets/image/ellipses/first-ellipse-purple.png";
import TelegramIcon from "../assets/image/telegram.png";
import Steps from "~@/assets/image/payment-steps.png";
import StepsMobile from "~@/assets/image/steps-mobile.png";
import TelegramWhite from "~@/assets/image/telegram-white.png";
import Line from "~@/assets/image/line.png";
import FirstEllipseMobilePurple from "~@/assets/image/ellipse-mobile.png";

const Telegram = () => {
  return (
    <>
      <div
        id="pay"
        className="flex flex-col relative mb-24 sm:min-h-screen xs:max-sm:mb-4"
      >
        <div className="absolute">
          <div className="absolute top-[25%] text-6xl font-bold text-green flex flex-col whitespace-nowrap max-lg:text-4xl ml-16 xs:max-sm:text-xl xs:max-sm:dark:text-white xs:max-sm:text-green xs:max-sm:ml-4 xs:max-sm:top-0">
            <div className="flex space-x-5">
              <span style={{ lineHeight: 1 }}>КАК ОПЛАТИТЬ</span>
              <div className="w-fit">
                <img
                  className="block xs:max-sm:hidden"
                  alt="telegram"
                  src={TelegramIcon}
                />
              </div>
            </div>
            <span style={{ lineHeight: 1 }}>ЧЕРЕЗ TELEGRAM БОТА?</span>
          </div>
          <img
            alt="ellipse"
            className="hidden xs:max-sm:block"
            src={FirstEllipseMobilePurple}
          />
          <img
            className="block xs:max-sm:hidden"
            alt="ellipse"
            src={FirstEllipsePurple}
          />
        </div>
        <div className="flex mt-[400px] px-36 max-lg:px-14 xs:max-sm:px-0 xs:max-sm:mt-14 xs:max-sm:pl-2.5 xs:max-sm:pr-14">
          <div className="xs:max-sm:flex">
            <img className="block xs:max-sm:hidden" alt="steps" src={Steps} />
            <div className="min-w-[40px] flex items-center">
              <img
                className="hidden xs:max-sm:block"
                alt="steps-mobile"
                src={StepsMobile}
              />
            </div>
            <div className="grid px-8 text-4xl text-green grid-cols-steps xs:max-sm:text-lg xs:max-sm:px-0 xs:max-sm:flex xs:max-sm:flex-col xs:max-sm:dark:text-white  xs:max-sm:ml-2.5 xs:max-sm:space-y-2.5 xs:max-sm:justify-between">
              <p className="ml-[10%] xs:max-sm:ml-0 xs:max-sm:flex xs:max-sm:flex-col">
                ШАГ 1
                <span className="hidden xs:max-sm:block">
                  Нажать “НОВЫЙ ОБМЕН” и выбрать направление перевода
                </span>
              </p>
              <p className="ml-[30%] xs:max-sm:ml-0 xs:max-sm:flex xs:max-sm:flex-col">
                ШАГ 2
                <span className="hidden xs:max-sm:block">
                  Указать сумму и реквизиты, на которые тебе нужно перевести
                  оплаты
                </span>
              </p>
              <p className="ml-[40%] xs:max-sm:ml-0 xs:max-sm:flex xs:max-sm:flex-col">
                ШАГ 3
                <span className="hidden xs:max-sm:block">
                  Перевести удобную для тебя валюту на выданные ботом реквизиты
                </span>
              </p>
              <p className="ml-[50%] xs:max-sm:ml-0 text-purple">
                ГОТОВО
                <span className="hidden xs:max-sm:block dark:text-white text-purple">
                  Чек об оплате товара уже у тебя!
                </span>
              </p>
            </div>
            <div className="grid text-3xl text-green grid-cols-steps mt-20 xs:max-sm:hidden">
              <p className="ml-[10%] xs:max-sm:ml-0">
                Нажать "НОВЫЙ ОБМЕН" и выбрать направление перевода
              </p>
              <p className="ml-[20%] xs:max-sm:ml-0">
                Указать сумму и реквизиты на которую тебе нужно перевести оплату
                за товар
              </p>
              <p className="ml-[20%] xs:max-sm:ml-0">
                Перевести удобную для тебя валюту на выданные ботом реквизиты
              </p>
              <p className="ml-[30%] text-purple xs:max-sm:ml-0">
                Чек об оплате товара уже у тебя!
              </p>
            </div>
          </div>
        </div>
        <button className="xs:max-sm:rounded bg-green py-10 px-11 text-white rounded-lg text-5xl mt-24 w-fit flex space-x-5 self-center xs:max-sm:text-base xs:max-sm:py-2.5 xs:max-sm:px-4 xs:max-sm:space-x-1.5 xs:max-sm:mt-4">
          <a
            href="https://t.me/GmExchange_bot?start=r_6145797218"
            target="_blank"
            className="self-center"
            rel="noreferrer"
          >
            Перейти в бота
          </a>
          <div className="self-center xs:max-sm:w-[14px]">
            <img alt="telegram-white" src={TelegramWhite} />
          </div>
        </button>
      </div>
      <img alt="line" src={Line} />
    </>
  );
};

export default Telegram;
