import Logo from "../assets/image/logo.png";
import { useState } from "react";

const Header = ({
  toggleTheme,
  isDarkTheme,
}: {
  toggleTheme: () => void;
  isDarkTheme: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scroll = (ref: string) => {
    const section = document.getElementById(ref);
    if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <header>
      <nav className="border-gray-200 fixed z-[100] min-[1024px]:hidden text-light-green w-full">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            data-collapse-toggle="navbar-hamburger"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-hamburger"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <label className="toggleDarkBtn">
            <input
              type="checkbox"
              defaultChecked={isDarkTheme}
              onClick={toggleTheme}
            />
            <span className="slideBtnTg round"></span>
          </label>
          <div
            className={`${isMenuOpen ? "block" : "hidden"} w-full`}
            id="navbar-hamburger"
          >
            <ul className="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 bg-gray-800 dark:border-gray-700 p-4 space-y-6">
              <a
                href="#0"
                onClick={(e) => {
                  e.preventDefault();
                  scroll("start");
                }}
                className="px-4 py-2.5 cursor-pointer"
              >
                <img className="h-[42px]" alt="logo" src={Logo} />
              </a>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("about");
                  }}
                >
                  Кто мы?
                </a>
              </li>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("forwho");
                  }}
                >
                  Для кого мы?
                </a>
              </li>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("diff");
                  }}
                >
                  Наши особенности
                </a>
              </li>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("pay");
                  }}
                >
                  Как оплатить?
                </a>
              </li>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("services");
                  }}
                >
                  Сервисы
                </a>
              </li>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("reviews");
                  }}
                >
                  Отзывы
                </a>
              </li>
              <li className="flex items-center whitespace-nowrap cursor-pointer">
                <a
                  className="w-full"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    scroll("faq");
                  }}
                >
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav className="xs:max-sm:hidden w-full bg-white dark:bg-dark-gray px-8 fixed left-0 z-30 tracking-[3px] py-4 xs:max-sm:px-4 xs:max-sm:tracking-normal xs2:max-sm:px-0 xs2:max-sm:px-3">
        <ul className="flex space-x-4 text-green text-nav dark:text-white text-dark-gray  xs:max-sm:space-x-1 xs:max-sm:items-center xs:max-sm:justify-end tracking-[1.12px] xs:max-sm:tracking-normal xs:max-[360px]:justify-center  xsmd:max-xs2:text-[7.5px] xs:max-xsmd:text-[6.5px] xs2:max-sm:text-[8px]">
          <a
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              scroll("start");
            }}
            className="bg-purple px-5 py-4 inline-flex items-center justify-center rounded-lg cursor-pointer xs:max-sm:min-w-[20px] xs:max-sm:h-[20px] xs:max-sm:p-1 xs:max-sm:rounded-[2px]"
          >
            <img
              className="w-[24px] xs:max-sm:w-[15px]"
              alt="logo"
              src={Logo}
            />
          </a>
          <label className="toggleDarkBtn self-center">
            <input
              type="checkbox"
              defaultChecked={isDarkTheme}
              onClick={toggleTheme}
            />
            <span className="slideBtnTg round"></span>
          </label>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("about");
              }}
            >
              Кто мы?
            </a>
          </li>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("forwho");
              }}
            >
              Для кого мы?
            </a>
          </li>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("diff");
              }}
            >
              Наши особенности
            </a>
          </li>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("pay");
              }}
            >
              Как оплатить?
            </a>
          </li>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("services");
              }}
            >
              Сервисы
            </a>
          </li>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("reviews");
              }}
            >
              Отзывы
            </a>
          </li>
          <li className="flex items-center whitespace-nowrap cursor-pointer">
            <a
              href="#0"
              onClick={(e) => {
                e.preventDefault();
                scroll("faq");
              }}
            >
              FAQ
            </a>
          </li>
        </ul>
      </nav>
      <div />
    </header>
  );
};

export default Header;
